import { getCacheKey, getClient, inCache } from "./client"

let cache = {}

type Args = {
  type:
    | "homepage"
    | "look_detail_page"
    | "collection_detail_page"
    | "collection_separate_items_page"
    | "content_page"
  lang: string
  urlSlug: string
}

export const getPageByUrlSlug = async ({ type, lang, urlSlug }: Args) => {
  const client = getClient()
  const key = getCacheKey([type, urlSlug, lang])

  if (inCache(cache, key)) {
    if (process.env.NEXT_PUBLIC_STAGE === "development") {
      console.info(`${key} returned from cache`)
    }
    return cache[key]
  }

  const item = await client
    .items()
    .type(type)
    .languageParameter(lang)
    .equalsFilter("elements.url_slug", urlSlug)
    .toPromise()
    .then((data) => (data && data.firstItem) || null)

  if (item && item.system.language === lang) {
    cache[key] = item
    return item
  } else {
    return null
  }
}
