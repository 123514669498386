import { ItemResponses } from "@kentico/kontent-delivery"
import { getCacheKey, getClient, inCache } from "./client"
import { LookDetailPage } from "./models-deprecated/look_detail_page"

let cache = {}

type Args = {
  lang: string
  skip?: number
  limit?: number
  skipId?: string
}

// We specifically don't use a generic function for this, becaus this way we can filter elements on the looks (for the grid view we only need a couple of elements)
export const getLooks = async ({
  lang,
  skip,
  limit = 9,
}: Args): Promise<ItemResponses.ListContentItemsResponse<LookDetailPage>> => {
  const client = getClient()
  const key = getCacheKey(["looks", skip, lang])

  if (inCache(cache, key)) {
    if (process.env.NEXT_PUBLIC_STAGE === "development") {
      console.info(`${key} returned from cache`)
    }
    return cache[key]
  }

  const items = await client
    .items<LookDetailPage>()
    .type("look_detail_page")
    .languageParameter(lang)
    .skipParameter(skip || 0)
    .limitParameter(limit)
    .includeTotalCountParameter()
    .orderByDescending("system.last_modified")
    // Add elements to this when needed
    .elementsParameter([
      "title",
      "vertical_image",
      "horizontal_image",
      "url_slug",
    ])

  cache[key] = items.toPromise()
  return cache[key]
}
