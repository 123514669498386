import { ContentItem, Elements } from "@kentico/kontent-delivery"

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class ContentPage extends ContentItem {
  public urlSlug: Elements.UrlSlugElement
  public seoMetaDescription: Elements.TextElement
  public seoMetaImage: Elements.AssetsElement
  public seoMetaTitle: Elements.TextElement
  public description: Elements.TextElement
  public pageTitle: Elements.TextElement
  public linkedItems: Elements.LinkedItemsElement<ContentItem>
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === "url_slug") {
          return "urlSlug"
        }
        if (elementName === "seo__meta_description") {
          return "seoMetaDescription"
        }
        if (elementName === "seo__meta_image") {
          return "seoMetaImage"
        }
        if (elementName === "seo__meta_title") {
          return "seoMetaTitle"
        }
        if (elementName === "page_title") {
          return "pageTitle"
        }
        if (elementName === "linked_items") {
          return "linkedItems"
        }
        return elementName
      },
    })
  }
}
