import { ContentItem, Elements } from "@kentico/kontent-delivery"

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class CollectionDetailPage extends ContentItem {
  public productDetailSeoDescription: Elements.TextElement
  public urlSlug: Elements.UrlSlugElement
  public firstCardTitle: Elements.TextElement
  public title: Elements.TextElement
  public seoMetaDescription: Elements.TextElement
  public seoMetaImage: Elements.AssetsElement
  public seoMetaTitle: Elements.TextElement
  public secondCardTitle: Elements.TextElement
  public firstCardText: Elements.TextElement
  public secondCardText: Elements.TextElement
  public description: Elements.TextElement
  public linkFile: Elements.AssetsElement
  public linkTitle: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === "product_detail_seo_description") {
          return "productDetailSeoDescription"
        }
        if (elementName === "url_slug") {
          return "urlSlug"
        }
        if (elementName === "first_card_title") {
          return "firstCardTitle"
        }
        if (elementName === "seo__meta_description") {
          return "seoMetaDescription"
        }
        if (elementName === "seo__meta_image") {
          return "seoMetaImage"
        }
        if (elementName === "seo__meta_title") {
          return "seoMetaTitle"
        }
        if (elementName === "second_card_title") {
          return "secondCardTitle"
        }
        if (elementName === "first_card_text") {
          return "firstCardText"
        }
        if (elementName === "second_card_text") {
          return "secondCardText"
        }
        if (elementName === "link_file") {
          return "linkFile"
        }
        if (elementName === "link_title") {
          return "linkTitle"
        }
        return elementName
      },
    })
  }
}
