import { ContentItem, Elements } from "@kentico/kontent-delivery"

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class WomenVote2022Winner extends ContentItem {
  public nominee: Elements.LinkedItemsElement<ContentItem>
  public categoryTitle: Elements.TextElement
  public winnerDescription: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === "category_title") {
          return "categoryTitle"
        }
        if (elementName === "winner_description") {
          return "winnerDescription"
        }
        return elementName
      },
    })
  }
}
