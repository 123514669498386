import { ContentItem, Elements } from "@kentico/kontent-delivery"

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class LookDetailPage extends ContentItem {
  public verticalImage: Elements.AssetsElement
  public galleryImages: Elements.AssetsElement
  public productCombinations: Elements.TextElement
  public seoMetaDescription: Elements.TextElement
  public seoMetaImage: Elements.AssetsElement
  public title: Elements.TextElement
  public horizontalImage: Elements.AssetsElement
  public seoMetaTitle: Elements.TextElement
  public urlSlug: Elements.UrlSlugElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === "vertical_image") {
          return "verticalImage"
        }
        if (elementName === "gallery_images") {
          return "galleryImages"
        }
        if (elementName === "product_combinations") {
          return "productCombinations"
        }
        if (elementName === "seo__meta_description") {
          return "seoMetaDescription"
        }
        if (elementName === "seo__meta_image") {
          return "seoMetaImage"
        }
        if (elementName === "horizontal_image") {
          return "horizontalImage"
        }
        if (elementName === "seo__meta_title") {
          return "seoMetaTitle"
        }
        if (elementName === "url_slug") {
          return "urlSlug"
        }
        return elementName
      },
    })
  }
}
