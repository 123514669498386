import Head from "next/head"
import React from "react"
import { navigation } from "./Intl/messages/navigation"
import { useIntl } from "./Intl"

interface Props {
  title: string
  description?: string | null
  imageUrl?: string
  urlKey?: string
  pathEn?: string | null
  pathNl?: string | null
}

const SEO: React.FC<Props> = ({
  title,
  description,
  imageUrl,
  urlKey,
  pathEn,
  pathNl,
  children,
}) => {
  const { messages, locale } = useIntl()
  let hrefEn = pathEn
  let hrefNl = pathNl

  let clientUrl =
    process.env.NEXT_PUBLIC_STAGE === "production"
      ? "https://www.choicesbydl.nl"
      : "http://localhost:3000"
  const desc = description ? description : messages.global.seoDescription

  // Lookup key in the navigation map
  if (urlKey) {
    hrefEn = urlKey === "home" ? "/en" : "/en" + navigation["en"][urlKey].url
    hrefNl = urlKey === "home" ? "/nl" : "/nl" + navigation["nl"][urlKey].url
  }
  const canonical = locale === "nl" ? hrefNl : hrefEn

  let between = title.length ? ` - ` : ""

  return (
    <Head>
      <title>
        {title}
        {between}Choices by DL
      </title>
      <meta name="title" content={`${title} - Choices by DL`} />
      <meta name="description" content={desc} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${title} - Choices by DL`} />
      <meta property="og:description" content={desc} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={`${title} - Choices by DL`} />
      <meta property="twitter:description" content={desc} />
      {imageUrl && <meta property="twitter:image" content={imageUrl} />}
      {hrefNl && (
        <link rel="alternate" hrefLang="nl" href={clientUrl + hrefNl} />
      )}
      {hrefEn && (
        <link rel="alternate" hrefLang="en" href={clientUrl + hrefEn} />
      )}
      {canonical && <link rel="canonical" href={clientUrl + canonical} />}
      {children}
    </Head>
  )
}

export default SEO
