import {
  ImageUrlBuilder,
  ImageCompressionEnum,
} from "@kentico/kontent-delivery"
type ImageArgs = {
  width?: number
  height?: number
  // TODO: Add more args here based on https://github.com/Kentico/kontent-delivery-sdk-js/blob/master/DOCS.md
}

export const getKontentImage = (imageUrl: string, args?: ImageArgs): string => {
  const asset = new ImageUrlBuilder(imageUrl).withCompression(
    ImageCompressionEnum.Lossless
  )
  // TODO: Add more args here based on https://github.com/Kentico/kontent-delivery-sdk-js/blob/master/DOCS.md
  if (args) {
    if (args.width) {
      asset.withWidth(args.width)
    }
    if (args.height) {
      asset.withHeight(args.height)
    }
  }

  return `${asset.getUrl()}&auto=format`
}
