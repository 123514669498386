import { ContentItem, Elements } from "@kentico/kontent-delivery"

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class WomenVote2022Page extends ContentItem {
  public sustainabilityNominees: Elements.LinkedItemsElement<ContentItem>
  public choicesText: Elements.RichTextElement
  public personalBrandingTitle: Elements.TextElement
  public votingClosedTitle: Elements.TextElement
  public heroSubtitle: Elements.TextElement
  public votingClosedVotingClosed: Elements.MultipleChoiceElement
  public winnersDescription: Elements.TextElement
  public abnText: Elements.RichTextElement
  public votingClosedDescription: Elements.TextElement
  public philanthropieSubtitle: Elements.TextElement
  public winnersTitle: Elements.TextElement
  public sustainabilityTitle: Elements.TextElement
  public displayWinnerSection: Elements.MultipleChoiceElement
  public heroTitle: Elements.TextElement
  public homepageBannerTitle: Elements.TextElement
  public homepageBannerDisplayHomepageBanner: Elements.MultipleChoiceElement
  public personalBrandingSubtitle: Elements.TextElement
  public homepageBannerDescription: Elements.TextElement
  public sustainabilitySubtitle: Elements.TextElement
  public philanthropieNominees: Elements.LinkedItemsElement<ContentItem>
  public photosDisplayPhotoSection: Elements.MultipleChoiceElement
  public personalBrandingNominees: Elements.LinkedItemsElement<ContentItem>
  public philanthropieTitle: Elements.TextElement
  public winnersWinners: Elements.LinkedItemsElement<ContentItem>
  public photosImages: Elements.AssetsElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === "sustainability_nominees") {
          return "sustainabilityNominees"
        }
        if (elementName === "choices_text") {
          return "choicesText"
        }
        if (elementName === "personal_branding_title") {
          return "personalBrandingTitle"
        }
        if (elementName === "voting_closed_title") {
          return "votingClosedTitle"
        }
        if (elementName === "hero_subtitle") {
          return "heroSubtitle"
        }
        if (elementName === "voting_closed_voting_closed") {
          return "votingClosedVotingClosed"
        }
        if (elementName === "winners_description") {
          return "winnersDescription"
        }
        if (elementName === "abn_text") {
          return "abnText"
        }
        if (elementName === "voting_closed_description") {
          return "votingClosedDescription"
        }
        if (elementName === "philanthropie_subtitle") {
          return "philanthropieSubtitle"
        }
        if (elementName === "winners_title") {
          return "winnersTitle"
        }
        if (elementName === "sustainability_title") {
          return "sustainabilityTitle"
        }
        if (elementName === "display_winner_section") {
          return "displayWinnerSection"
        }
        if (elementName === "hero_title") {
          return "heroTitle"
        }
        if (elementName === "homepage_banner_title") {
          return "homepageBannerTitle"
        }
        if (elementName === "homepage_banner_display_homepage_banner") {
          return "homepageBannerDisplayHomepageBanner"
        }
        if (elementName === "personal_branding_subtitle") {
          return "personalBrandingSubtitle"
        }
        if (elementName === "homepage_banner_description") {
          return "homepageBannerDescription"
        }
        if (elementName === "sustainability_subtitle") {
          return "sustainabilitySubtitle"
        }
        if (elementName === "philanthropie_nominees") {
          return "philanthropieNominees"
        }
        if (elementName === "photos_display_photo_section") {
          return "photosDisplayPhotoSection"
        }
        if (elementName === "personal_branding_nominees") {
          return "personalBrandingNominees"
        }
        if (elementName === "philanthropie_title") {
          return "philanthropieTitle"
        }
        if (elementName === "winners_winners") {
          return "winnersWinners"
        }
        if (elementName === "photos_images") {
          return "photosImages"
        }
        return elementName
      },
    })
  }
}
