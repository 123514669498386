import { ContentItem, Elements } from "@kentico/kontent-delivery"

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class RetailersRetailerBlock extends ContentItem {
  public phoneNumber: Elements.TextElement
  public sunday: Elements.TextElement
  public thursday: Elements.TextElement
  public subtitle: Elements.TextElement
  public emailAddress: Elements.TextElement
  public saturday: Elements.TextElement
  public addressInfo: Elements.TextElement
  public friday: Elements.TextElement
  public websiteUrl: Elements.TextElement
  public tuesday: Elements.TextElement
  public monday: Elements.TextElement
  public wednesday: Elements.TextElement
  public title: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === "phone_number") {
          return "phoneNumber"
        }
        if (elementName === "email_address") {
          return "emailAddress"
        }
        if (elementName === "address_info") {
          return "addressInfo"
        }
        if (elementName === "website_url") {
          return "websiteUrl"
        }
        return elementName
      },
    })
  }
}
