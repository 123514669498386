import { ContentItem, Elements } from "@kentico/kontent-delivery"

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class HomepageBannerBlock extends ContentItem {
  public intro: Elements.TextElement
  public linkText: Elements.TextElement
  public mobile: Elements.AssetsElement
  public tabletDesktop: Elements.AssetsElement
  public linkUrl: Elements.TextElement
  public style: Elements.MultipleChoiceElement
  public title: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === "link_text") {
          return "linkText"
        }
        if (elementName === "tablet___desktop") {
          return "tabletDesktop"
        }
        if (elementName === "link_url") {
          return "linkUrl"
        }
        return elementName
      },
    })
  }
}
