import { ContentItem, Elements } from "@kentico/kontent-delivery"

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class HomepageIntroBlock extends ContentItem {
  public video: Elements.AssetsElement
  public linkTitle: Elements.TextElement
  public image: Elements.AssetsElement
  public title: Elements.TextElement
  public linkFile: Elements.AssetsElement
  public text: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === "link_title") {
          return "linkTitle"
        }
        if (elementName === "link_file") {
          return "linkFile"
        }
        return elementName
      },
    })
  }
}
