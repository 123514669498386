import { getCacheKey, getClient, inCache } from "./client"

let cache = {}

type Args = {
  codename: string
  lang: string
}

export const getPageByCodename = async ({ codename, lang }: Args) => {
  const client = getClient()
  const key = getCacheKey([codename, lang])

  if (inCache(cache, key)) {
    if (process.env.NEXT_PUBLIC_STAGE === "development") {
      console.info(`${key} returned from cache`)
    }
    return cache[key]
  }

  const item = await client
    .item(codename)
    .languageParameter(lang)
    .depthParameter(2)
    .toPromise()
  // If an item is requested with a language that doens't exist, Kontent will fallback to the default language
  // we don't want that, so we check if the item is in the language that is requested
  if (item && item.item.system.language === lang) {
    cache[key] = item
    return item
  } else {
    return null
  }
}
