import { ContentItem, Elements } from "@kentico/kontent-delivery"

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class WomenVote2022Nominee extends ContentItem {
  public name: Elements.TextElement
  public mainImage: Elements.AssetsElement
  public jobDescription: Elements.TextElement
  public contentText: Elements.RichTextElement
  public contentImages: Elements.AssetsElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === "main_image") {
          return "mainImage"
        }
        if (elementName === "job_description") {
          return "jobDescription"
        }
        if (elementName === "content_text") {
          return "contentText"
        }
        if (elementName === "content_images") {
          return "contentImages"
        }
        return elementName
      },
    })
  }
}
