import { ContentItem, Elements } from "@kentico/kontent-delivery"

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Homepage extends ContentItem {
  public retailersTitle: Elements.TextElement
  public collectionTitle: Elements.TextElement
  public experienceText: Elements.TextElement
  public socialTitle: Elements.TextElement
  public looksTitle: Elements.TextElement
  public collectionText: Elements.TextElement
  public socialFacebookImage: Elements.AssetsElement
  public seoMetaDescription: Elements.TextElement
  public seoMetaImage: Elements.AssetsElement
  public looksItems: Elements.LinkedItemsElement<ContentItem>
  public retailersText: Elements.TextElement
  public instagramPreviewImage: Elements.AssetsElement
  public seoMetaTitle: Elements.TextElement
  public instagramAutoplayVideo: Elements.AssetsElement
  public looksText: Elements.TextElement
  public banner: Elements.LinkedItemsElement<ContentItem>
  public experienceTitle: Elements.TextElement
  public instagramDescription: Elements.TextElement
  public introBlocks: Elements.LinkedItemsElement<ContentItem>
  public instagramTitle: Elements.TextElement
  public socialInstagramImage: Elements.AssetsElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === "retailers_title") {
          return "retailersTitle"
        }
        if (elementName === "collection_title") {
          return "collectionTitle"
        }
        if (elementName === "experience_text") {
          return "experienceText"
        }
        if (elementName === "social_title") {
          return "socialTitle"
        }
        if (elementName === "looks_title") {
          return "looksTitle"
        }
        if (elementName === "collection_text") {
          return "collectionText"
        }
        if (elementName === "social_facebook_image") {
          return "socialFacebookImage"
        }
        if (elementName === "seo__meta_description") {
          return "seoMetaDescription"
        }
        if (elementName === "seo__meta_image") {
          return "seoMetaImage"
        }
        if (elementName === "looks_items") {
          return "looksItems"
        }
        if (elementName === "retailers_text") {
          return "retailersText"
        }
        if (elementName === "instagram_preview_image") {
          return "instagramPreviewImage"
        }
        if (elementName === "seo__meta_title") {
          return "seoMetaTitle"
        }
        if (elementName === "instagram_autoplay_video") {
          return "instagramAutoplayVideo"
        }
        if (elementName === "looks_text") {
          return "looksText"
        }
        if (elementName === "experience_title") {
          return "experienceTitle"
        }
        if (elementName === "instagram_description") {
          return "instagramDescription"
        }
        if (elementName === "intro_blocks") {
          return "introBlocks"
        }
        if (elementName === "instagram_title") {
          return "instagramTitle"
        }
        if (elementName === "social_instagram_image") {
          return "socialInstagramImage"
        }
        return elementName
      },
    })
  }
}
