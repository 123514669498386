import { LookDetailPage } from "@lib/kontent/models-deprecated/look_detail_page"
import { getCacheKey, getClient, inCache } from "./client"

let cache = {}

type Args = {
  afterDate: string
  lang: string
}

export const getNextLookUrlSlug = async ({
  afterDate,
  lang,
}: Args): Promise<string> => {
  const client = getClient()
  const key = getCacheKey(["nextLookUid", afterDate, lang])

  if (inCache(cache, key)) {
    if (process.env.NEXT_PUBLIC_STAGE === "development") {
      console.info(`${key} returned from cache`)
    }
    return cache[key]
  }

  const next = await client
    .items<LookDetailPage>()
    .type("look_detail_page")
    .languageParameter(lang)
    .limitParameter(2)
    .orderByDescending("system.last_modified")
    .lessThanFilter("system.last_modified", afterDate)
    .elementsParameter(["url_slug"])
    .toPromise()
  if (next && next.items && next.lastItem) {
    cache[key] = next.lastItem.urlSlug.value
  } else {
    const first = await client
      .items<LookDetailPage>()
      .type("look_detail_page")
      .languageParameter(lang)
      .limitParameter(1)
      .orderByDescending("system.last_modified")
      .elementsParameter(["url_slug"])
      .toPromise()
    cache[key] = first.firstItem?.urlSlug.value
  }
  return cache[key]
}
