import { DeliveryClient, TypeResolver } from "@kentico/kontent-delivery"
import { CollectionDetailPage } from "./models-deprecated/collection_detail_page"
import { ContactPage } from "./models-deprecated/contact_page"
import { ContentPage } from "./models-deprecated/content_page"
import { Homepage } from "./models-deprecated/homepage"
import { HomepageBannerBlock } from "./models-deprecated/homepage_banner___block"
import { HomepageIntroBlock } from "./models-deprecated/homepage_intro___block"
import { LookDetailPage } from "./models-deprecated/look_detail_page"
import { LooksPage } from "./models-deprecated/looks_page"
import { NewsletterPage } from "./models-deprecated/newsletter_page"
import { RetailersRetailerBlock } from "./models-deprecated/retailers___retailer_block"
import { RetailersPage } from "./models-deprecated/retailers_page"
import { ReturnPage } from "./models-deprecated/return_page"
import { SearchPage } from "./models-deprecated/search_page"
import { WarrantyPage } from "./models-deprecated/warranty_page"
import { WomenVote2022Nominee } from "./models-deprecated/women_vote_2022_nominee"
import { WomenVote2022Page } from "./models-deprecated/women_vote_2022_page"
import { WomenVote2022Winner } from "./models-deprecated/women_vote_2022_winner"

export const inCache = (cache, key: string): boolean =>
  typeof window !== "undefined" && typeof cache[key] !== "undefined"

export const getCacheKey = (arr: any[]): string =>
  arr
    .flat()
    .filter((k) => ["string", "number"].includes(typeof k))
    .join("|")

let client: null | DeliveryClient = null

export const getClient = (): DeliveryClient => {
  if (client) {
    return client
  }

  client = new DeliveryClient({
    projectId: "d1cf10f7-8b9a-0118-4bbc-02df26d66a7b",
    previewApiKey:
      "ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjk0MDQwOWQ4NWMyYjQ4OGY4ZjNiNTk2MTJjOWRlODI1IiwNCiAgImlhdCI6ICIxNTgyNTM0OTkzIiwNCiAgImV4cCI6ICIxOTI4MTM0OTkzIiwNCiAgInByb2plY3RfaWQiOiAiZDFjZjEwZjc4YjlhMDExODRiYmMwMmRmMjZkNjZhN2IiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.lDvjWNEUOknktY_HOgQB-21mYPQnEJTdT_tK23jLTYk",
    globalQueryConfig: {
      usePreviewMode:
        process.env.NEXT_PUBLIC_STAGE === "development" ||
        process.env.NEXT_PUBLIC_STAGE === "preview",
    },
    typeResolvers: [
      new TypeResolver(
        "collection_detail_page",
        () => new CollectionDetailPage()
      ),
      new TypeResolver("homepage", () => new Homepage()),
      new TypeResolver("look_detail_page", () => new LookDetailPage()),
      new TypeResolver(
        "homepage_banner___block",
        () => new HomepageBannerBlock()
      ),
      new TypeResolver("homepage_intro__block", () => new HomepageIntroBlock()),
      new TypeResolver("looks_page", () => new LooksPage()),
      new TypeResolver("newsletter_page", () => new NewsletterPage()),
      new TypeResolver("return_page", () => new ReturnPage()),
      new TypeResolver("warranty_page", () => new WarrantyPage()),
      new TypeResolver("contact_page", () => new ContactPage()),
      new TypeResolver("retailers_page", () => new RetailersPage()),
      new TypeResolver("retailers___region_block", () => new RetailersPage()),
      new TypeResolver(
        "retailers___retailer_block",
        () => new RetailersRetailerBlock()
      ),
      new TypeResolver("content_page", () => new ContentPage()),
      new TypeResolver("search_page", () => new SearchPage()),
      new TypeResolver("women_vote_2022_page", () => new WomenVote2022Page()),
      new TypeResolver(
        "women_vote_2022_nominee",
        () => new WomenVote2022Nominee()
      ),
      new TypeResolver(
        "women_vote_2022_winner",
        () => new WomenVote2022Winner()
      ),
    ],
  })
  return client
}
