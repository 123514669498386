import { ContentItem, Elements } from "@kentico/kontent-delivery"

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class LooksPage extends ContentItem {
  public seoMetaDescription: Elements.TextElement
  public seoMetaImage: Elements.AssetsElement
  public seoMetaTitle: Elements.TextElement
  public text: Elements.TextElement
  public title: Elements.TextElement
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === "seo__meta_description") {
          return "seoMetaDescription"
        }
        if (elementName === "seo__meta_image") {
          return "seoMetaImage"
        }
        if (elementName === "seo__meta_title") {
          return "seoMetaTitle"
        }
        return elementName
      },
    })
  }
}
